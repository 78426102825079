import axios from 'axios';


const api = axios.create(
    {
       // baseURL: 'http://localhost:5000/api_3',
       // baseURL: 'http://192.168.1.10:5000/api_1',
        baseURL: 'https://beitec.com.br/api_3',

        headers: { 
               'Content-Type': 'application/json',
            
         },
    }
)

export default api;
import './App.scss';
import img_a from '../src/assets/a.png';
import img_b from '../src/assets/b.png';
import img_c from '../src/assets/c.png';
import img_d from '../src/assets/d.png';
import img_e from '../src/assets/e.png';
import img_f from '../src/assets/f.png';
import img_g from '../src/assets/g.png';
import img_h from '../src/assets/h.png';
import img_z from '../src/assets/z.png';

import React, {useEffect, useState,useContext, useRef, Children, useCallback} from 'react';
import { Carousel,Button,Input, Spin } from 'antd';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Collapse } from 'antd';
import { LeftCircleOutlined,RightCircleOutlined,CheckCircleOutlined,SmileOutlined,FrownOutlined } from '@ant-design/icons';
import api from './services/api';

const { Panel } = Collapse;

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

function App() {
  const [items, setItems] = useState(getItems);
  const [position, setPosition] = useState(0);
  const [busca, setBusca] = useState("");
  const [page, setPage] = useState(1);
  const [carregando, setCarregando] = useState(false);

  const [fNome, setFNome] = useState("");
  const [fEmail, setFEmail] = useState("");
  const [fWhats, setFWhats] = useState("");
  const [soma_a, setSoma_a] = useState(Math.floor(Math.random() * 10) + 1);
  const [soma_b, setSoma_b] = useState(Math.floor(Math.random() * 5) + 1);
  const [soma_res, setSoma_res] = useState(0);

  const [concordo, setConcordo] = useState(false);
  

function enviarForm(){

    if(fNome.length > 3 && fNome.length < 45){
      if((fEmail.length > 3 && fNome.length < 45) || (fWhats.length > 3 && fWhats.length < 18)){
     
        if(soma_res == (soma_a + soma_b)){
          async function envia(){
                setCarregando(true);
                try {                
                  const resp = await api.post(`/enviaForm`, {
                    code:String("UNILA42101"),
                    form:String("U4487"),
                    nome:String(fNome),
                    email:String(fEmail),
                    fone:String(""),
                    whats:String(fWhats),
                    aux_a:String(concordo),
                    aux_b:String(""),
                  });   
                  //alert(JSON.stringify(resp.data));
                  if((resp.data.message) == "OK"){
                      //OK
                      setCarregando(false);
                      setPage(2);
                      return;
                  }
    
              }catch(e){
                 // toast.error('Erro.. '+e);
              }
              setCarregando(false);
              setPage(3); // pagina de erro
          }
          envia();       
        }else{
          setCarregando(false);
          alert('Soma incorreta, tente novamente.');
        }
      }else{
        setCarregando(false);
      }
    }else{
      setCarregando(false);
    }
}
  function txNormal(text)
  {
      const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
      const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
      const p = new RegExp(a.split('').join('|'), 'g')
      return text.toString().toLowerCase().trim()
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
    }

  return (
    <div className="App">
      <div className="img-topo" style={{backgroundImage: 'url('+img_z+')'}}>
        <img src={img_a} style={{height:"180px"}}/> 
      </div>
      <div className="box-amarelo">
          SÃO LEOPOLDO
      </div>
      <div className="box-form">
        <div className="box-form-a">
          <div>
            Nos cursos a distância da <strong>Universidade La Salle</strong> você vive uma experiência de ensino inovadora com interação ao vivo entre professores e colegas, além de contar com o apoio de uma das maiores plataformas de ensino do mundo, o <strong>Google Workspace for Education.</strong>
          </div>
          <div>
          <img src={img_b} style={{height:"20px",width:"100%"}}/> 
          </div>
          <div>
          <img src={img_c} style={{height:"123px"}}/> 
          </div>
          <div style={{backgroundColor: "rgb(0, 60, 127)", color: "rgb(244, 194, 22)", fontFamily: "Arial Black", fontWeight: "bold", fontSize: "30px", padding:"8px"}}>
            INVISTA EM VOCÊ
          </div>
          <div style={{color: "rgb(0, 60, 127)",  fontSize: "22px"}}>
            Aproveite a matrícula de R$ 99,90 por <strong>apenas R$ 69,00</strong> e estude com o melhor do ensino superior a distância
          </div>
        </div>
        <div className="box-form-b">
          {page == 1 && <>
          <div className="form">
            <div className="form-titulo">
              Preencha para garantir um desconto especial!
            </div>
            <div className="form-sub">
              Válido para ingresso em 2023.
            </div>
          
            <div className="form-input">
              Nome completo*
              <Input id="nome"  defaultValue="" onChange={(e) => { setFNome(e.target.value)  }} value={fNome} />
            </div>
            <div className="form-input">
              Email*
              <Input id="email"  defaultValue=""  onChange={(e) => { setFEmail(e.target.value)  }} value={fEmail}/>
            </div>
            <div className="form-input">
              Celular*
              <Input id="celular" defaultValue="" placeholder='DDD 9 0000 0000' onChange={(e) => { setFWhats(e.target.value)  }} value={fWhats}/>
            </div>
            <div className="form-input">
              {soma_a} + {soma_b} = ?
              <Input id="curso"  defaultValue=""  onChange={(e) => { setSoma_res(e.target.value)  }} value={soma_res}/>
            </div>
            <div className="form-input-check" onClick={()=>{ setConcordo(!concordo) }}>
            <input checked={concordo} onChange={setConcordo} type='checkbox'/>
              Eu concordo em receber comunicações.
              
            </div>
            <div className="form-informe">
              Ao informar meus dados, eu concordo com a Política de Privacidade.
            </div>
            <div className="form-button">
               <Button loading={carregando} type="primary" onClick={ () => { enviarForm(); }}>{carregando ? "Enviando...":"Enviar"}</Button>
            </div>
          </div>
          </>}

        { page == 2 && <>
              <div className="lead lead-obrigado" >
                <SmileOutlined style={{fontSize: '42px', color: 'white', marginBottom:'25px'}}/>
                <p>Formulário Enviado.</p> 
                <p>Entraremos em contato.</p> 
              </div>
              </> }
          { page == 3 && <>
              <div className="lead lead-erro" >
                <FrownOutlined style={{fontSize: '42px', color: 'white', marginBottom:'25px'}}/>
                <p>Erro ao enviar formulário</p> 
                <Button loading={carregando} type="primary" onClick={ () => { setPage(1); }}>Voltar</Button>
              </div>
              </> }
        </div>
      </div>
      <div className="box-azul-grid">
        <div className="grid-item grid-a" style={{display:"flex"}}>
          <img src={img_d} style={{height:"123px"}}/> 
          <div style={{display:"flex", flexDirection:"column", justifyContent:"center", padding:"25px", alignItems:"center", gap:"10px"}}>
            <div style={{color: "rgb(0, 60, 127)", backgroundColor: "rgb(244, 194, 22)", fontFamily: "Helvetica, Arial, sans-serif", fontWeight: "bold", fontSize: "20px"}}>
                Mediação Continuada
            </div>
            <div style={{color:"white", fontSize:"16px", fontWeight: "bold", textAlign:"center"}}>
              Suporte de docentes e tutores, preparados para tirar dúvidas sobre a disciplina.
            </div>
          </div>
        </div>
        <div className="grid-item grid-b" style={{display:"flex"}}>
          <img src={img_e} style={{height:"123px"}}/> 
          <div style={{display:"flex", flexDirection:"column", justifyContent:"center", padding:"25px", alignItems:"center", gap:"10px"}}>
            <div style={{color: "rgb(0, 60, 127)", backgroundColor: "rgb(244, 194, 22)", fontFamily: "Helvetica, Arial, sans-serif", fontWeight: "bold", fontSize: "20px"}}>
            Biblioteca Virtual
            </div>
            <div style={{color:"white", fontSize:"16px", fontWeight: "bold", textAlign:"center"}}>
            Vasto acervo de materiais disponível para consulta.
            </div>
          </div>
        </div>
        <div className="grid-item grid-c" style={{display:"flex"}}>
          <img src={img_f} style={{height:"123px"}}/> 
          <div style={{display:"flex", flexDirection:"column", justifyContent:"center", padding:"25px", alignItems:"center", gap:"10px"}}>
            <div style={{color: "rgb(0, 60, 127)", backgroundColor: "rgb(244, 194, 22)", fontFamily: "Helvetica, Arial, sans-serif", fontWeight: "bold", fontSize: "20px"}}>
            Videoaulas Dinâmicas
            </div>
            <div style={{color:"white", fontSize:"16px", fontWeight: "bold", textAlign:"center"}}>
            Animações 2D, informações objetivas, com a interação de professores e atores.
            </div>
          </div>
        </div>
        <div className="grid-item grid-d" style={{display:"flex"}}>
          <img src={img_g} style={{height:"123px"}}/> 
          <div style={{display:"flex", flexDirection:"column", justifyContent:"center", padding:"25px", alignItems:"center", gap:"10px"}}>
            <div style={{color: "rgb(0, 60, 127)", backgroundColor: "rgb(244, 194, 22)", fontFamily: "Helvetica, Arial, sans-serif", fontWeight: "bold", fontSize: "20px"}}>
            Mobilidade Digital
            </div>
            <div style={{color:"white", fontSize:"16px", fontWeight: "bold", textAlign:"center"}}>
            Acesse suas aulas de onde estiver, através de um computador, tablet ou celular.
            </div>
          </div>
        </div>
      </div>
      <div className="box-branco">
        <div className="box-branco-titulo">
          CONHEÇA A UNIDADE EAD EM SÃO LEOPOLDO.
        </div>
        <div className="box-branco-contatos">
          <a href={"https://api.whatsapp.com/send?phone=5551974002463"} target="_blank">
            <img src="https://img.icons8.com/color/48/null/whatsapp--v1.png" style={{width:"47px"}}/>
            <div>
              <p>WhatsApp</p>
              <p style={{fontSize:"14px"}}>(51) 3253-3332</p>           
            </div>
            </a>
          
          <a className="contato" href='mailto:polo.saoleopoldo@unilasalle.edu.br' target="_blank" >
            <img src="https://img.icons8.com/fluency/48/null/mail.png" style={{width:"47px"}}/>
            <div>
              <p>Email</p>
              <p style={{fontSize:"14px"}}>polo.saoleopoldo@unilasalle.edu.br</p>           
            </div>
            </a>
          
          <a className="contato" href='http://Facebook.com/unilasallesaoleopoldo' target="_blank">
            <img src="https://img.icons8.com/color/48/null/facebook-new.png" style={{width:"47px"}}/>
            <div>
              <p>Facebook</p>
              <p style={{fontSize:"14px"}}>/unilasallesaoleopoldo</p>           
            </div>
            </a>
          
          <a className="contato" href='https://www.instagram.com/unilasallesaoleopoldo/' target="_blank">
            <img src="https://img.icons8.com/fluency/48/null/instagram-new.png" style={{width:"47px"}}/><div>
              <p>Intagram</p>
              <p style={{fontSize:"14px"}}>@unilasallesaoleopoldo</p>           
            </div>
            </a>
          
          <a href="https://linktr.ee/unilasallesaoleopoldo" target="_blank">
            <img src="https://img.icons8.com/fluency/48/null/link.png" style={{width:"47px"}}/>
            Veja mais no linktree</a>

        </div>
        
      </div>
      <div className="box-fim">
      <img src={img_h} style={{height:"83px"}}/> 
      </div>
    </div>
  );
}

export default App;
